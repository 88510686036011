
import {
    computed,
    defineComponent, inject,
    PropType,
    ref,
    watch,
    watchEffect
} from 'vue';
import { ListContent } from '../util/all-interface';

export default defineComponent({
    props: {
        tableData: {
            type: Array as PropType<Array<ListContent>>,
            required: true
        },
        keyOfCheck: {
            type: String,
            required: true
        },
        modelValue: {
            type: Array as PropType<Array<string>>,
            required: true
        },
        virtualData: {
            type: Array as PropType<Array<ListContent>>,
            default: () => []
        },
        scrollTop: {
            type: Number,
            default: 0
        },
        disableCheckCondition: {
            type: Function,
            default: () => false
        }
    },
    setup(props, { emit }) {
        // isOpenVirtual：是否开启虚拟类别功能
        const isOpenVirtual = inject('isOpenVirtual') ? inject('isOpenVirtual') : false;

        const isAllChoose = ref(false);

        // 筛选可以被勾选的表格数据
        const checkableTableData = computed<ListContent[]>(() => props.tableData.filter((item) => !props.disableCheckCondition(item)));

        const chooseValue = ref(props.modelValue);
        watchEffect(() => {
            emit('update:modelValue', chooseValue.value);
            if (chooseValue.value.length === 0 || chooseValue.value.length !== checkableTableData.value.length) isAllChoose.value = false;
            else if (chooseValue.value.length === checkableTableData.value.length) isAllChoose.value = true;
        });

        watch(computed(() => props.modelValue), () => {
            chooseValue.value = props.modelValue;
        });

        const chooseAll = () => {
            if (isAllChoose.value === true) {
                const temValue: Array<string> = [];
                checkableTableData.value.forEach((value) => {
                    temValue.push(value[props.keyOfCheck]);
                });
                chooseValue.value = temValue;
            } else {
                chooseValue.value = [];
            }
        };

        return {
            isAllChoose,
            chooseValue,
            chooseAll,
            isOpenVirtual
        };
    }
});
