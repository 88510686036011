import router from '@/router';
import { SearchParams } from './all-interface';
/**
 * @name 从url中获取参数
 * @return key: value类型的参数值
 */
export default null;
export function getSearch<T extends SearchParams>(data: T) {
    // search部分的url必须encodeURIComponent,用户可能会输入特殊字符
    const searchParam = window.location.href.split('?');
    if (searchParam.length < 2) return data;

    const params = new URLSearchParams(searchParam[1]);
    const result: SearchParams = {};

    Object.keys(data).forEach((key) => {
        const ele = params.get(key);
        if (ele) {
            result[key] = ele;
        } else {
            result[key] = data[key];
        }
    });
    return result as T;
}

// export function getSearch<T extends SearchParams>(data: T) {
//     const result = ref<SearchParams>({});
//     // search部分的url必须encodeURIComponent,用户可能会输入特殊字符
//     const setParam = () => {
//         const searchParam = window.location.href.split('?');
//         if (searchParam.length < 2) result.value = data;
//         else {
//             const params = new URLSearchParams(decodeURIComponent(searchParam[1]));
//             Object.keys(data).forEach((key) => {
//                 result.value[key] = params.get(key) || data[key];
//             });
//         }
//     };
//     setParam();
//     return result as Ref<T>;
// }

/**
 * @name 设置当前页搜索参数
 */
export function setCurrentSearchParam(param: SearchParams) {
    const search: Array<string> = [];
    Object.keys(param).forEach((key) => {
        search.push(`${key}=${encodeURIComponent(param[key])}`);
    });
    const { query } = router.currentRoute.value;
    Object.keys(query).forEach((key) => {
        if (!Object.keys(param).includes(key)) search.push(`${key}=${encodeURIComponent(query[key]!.toString())}`);
    });
    router.push(`${router.currentRoute.value.path}?${search.join('&')}`);
}