/* eslint-disable no-param-reassign */
import { Ref } from 'vue';
import HttpRequest from '@/util/axios.config';
import deleteDefaultFunc from '@/methods/remove-func';

import {
    ListContent,
    ListRequestResult,
    ListRequestResultNoRow,
    RequestData,
    ListActionItem
} from './all-interface';
import { isNotUseRequestData, isListRequestResultNoRow, isArrayRequestResult } from './util';

const setListData = (
    requestData: RequestData | false,
    listData: Array<ListContent>,
    tableData: Ref< Array<ListContent> >,
    requestDetail: Ref< Array<ListContent> >,
    totalCount: Ref<number>,
    currentPageCount: Ref<number>,
    dealList: Function,
    callback: () => void
): void => {
    // 数据获取模式,外部导入还是请求获取
    if (isNotUseRequestData(requestData)) {
        tableData.value = listData;
        requestDetail.value = listData;
        totalCount.value = listData.length;
    } else {
        // tableData.value = [];
        const { url, param } = requestData;

        // url 为空不该执行接口请求
        if (url === '') {
            return;
        }

        HttpRequest.get(url, param, (result: ListRequestResult | ListRequestResultNoRow) => {
            tableData.value = [];
            if (isListRequestResultNoRow(result)) {
                let { data } = result;
                data = dealList(data);
                tableData.value = data;
                requestDetail.value = data;
                totalCount.value = data.length;
            } else {
                const { data } = result;
                // 目前后台请求接口返回的data存在直接是数组的情况，以及total在最外一层的情况
                if (isArrayRequestResult(data)) {
                    tableData.value = data;
                    requestDetail.value = data;
                    totalCount.value = Number(result.total || data.length);
                    currentPageCount.value = Number(data.length);
                } else {
                    tableData.value = data.row;
                    requestDetail.value = data.detail;
                    totalCount.value = Number(data.total);
                    currentPageCount.value = Number(tableData.value.length);
                }
            }
            callback();
        });
    }
};

// action 显示和处理
const actionClass = {
    delete: 'el-icon-delete delete-icon',
    edit: 'el-icon-my-modify modify-icon',
    info: 'el-icon-my-info info-icon'
};

const getDefaultAction = (keyOfDelete: string): Array<ListActionItem> => [{
    type: 'info',
    event: 'info',
    title: WordList.PersonuserInfo
},
{
    type: 'edit',
    event: 'edit',
    title: WordList.TabelConfigInHtmlModify
},
{
    type: 'delete',
    event(value, url: string, message: string, update: Ref<number>) {
        deleteDefaultFunc(
            message,
            () => {
                HttpRequest.post(url, {
                    [keyOfDelete]: value[keyOfDelete]
                }, () => {
                    update.value += 1;
                });
            }
        );
    },
    title: WordList.TabelConfigInHtmlDelete
}];

const defaultAction: Array<ListActionItem> = [
    {
        type: 'info',
        event: 'info',
        title: WordList.PersonuserInfo
    },
    {
        type: 'edit',
        event: 'edit',
        title: WordList.TabelConfigInHtmlModify
    },
    {
        type: 'delete',
        event(value, url: string, message: string, update: Ref<number>, ...argus) {
            deleteDefaultFunc(
                message,
                () => {
                    HttpRequest.post(url, {
                        ID: value.ID
                    }, () => {
                        update.value += 1;
                    });
                }
            );
        },
        title: WordList.TabelConfigInHtmlDelete
    }
];

const defaultActionType = ['info', 'delete', 'edit'];

export {
    defaultAction,
    actionClass,
    defaultActionType,
    setListData,
    getDefaultAction
};
