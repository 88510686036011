import { PropType } from 'vue';
import {
    ListHeaderItem,
    ListContent,
    ListActionItem,
    ActionType,
    RequestData
} from './all-interface';
import { defaultActionType } from './list-data';

export default {
    // 列表头数据
    headers: {
        type: Array as PropType< Array<ListHeaderItem> >,
        require: true
    },
    // 直接传入的列表数据,如果requestData不为false,这个值将无效
    listData: {
        type: Array as PropType< Array<ListContent> >,
        default: []
    },
    // 是否使用网络请求获取列表数据
    requestData: {
        type: [Object, Boolean] as PropType<RequestData | false>,
        default: false
    },
    dealList: {
        type: Function,
        default: (data: any) => data
    },
    // 删除action相关参数
    deleteUrl: {
        type: String,
        default: ''
    },
    keyOfDelete: {
        type: String,
        default: 'ID'
    },
    // 删除action相关参数
    deleteMessage: {
        type: String,
        default: WordList.TabelDeleteText
    },
    // 删除action相关参数
    deleteMultipleUrl: {
        type: String,
        default: ''
    },
    // 删除action相关参数
    deleteMultipleMessage: {
        type: [String, Function] as PropType<string | ((data: any) => string)>,
        default: WordList.ProperAllTextDeleteTip
    },
    deleteMultipleKeyType: {
        type: String as PropType<'string' | 'array'>,
        default: 'string'
    },
    disableCheckCondition: {
        type: Function,
        default: () => false
    },
    // 额外的,除去默认的,action列数据
    action: {
        type: [Array, Boolean] as PropType< Array<ListActionItem> | false >,
        default() {
            return [];
        }
    },
    showDefaultAction: {
        type: Array as PropType< Array<ActionType> >,
        default() {
            return defaultActionType;
        }
    },
    // 是否显示勾选
    showCheck: {
        type: [Boolean, String] as PropType<boolean | 'OnlyCheck'>,
        default: true
    },
    // 初始化勾选数据
    selectedItem: {
        type: Array as PropType< Array<string> >,
        default() {
            return [];
        }
    },
    // 勾选所使用列表项的key
    keyOfCheck: {
        type: String,
        default: 'ID'
    },
    // 列表更新
    update: {
        type: Number,
        default: 0
    },
    saveParamInPath: {
        type: Boolean,
        default: false
    },
    // 是否重置勾选项
    isClearCheck: {
        type: Boolean,
        default: true
    }
};
